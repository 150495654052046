var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.properties.createVisible)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.clicked('create')}}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}],null,false,1903179319)},[_c('span',[_vm._v(_vm._s(_vm.properties.createButton || _vm.$t('text.create-record')))])]):_vm._e(),(_vm.properties.saveVisible)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.clicked('save')}}},on),[_c('v-icon',[_vm._v("mdi-content-save")])],1)]}}],null,false,2796073677)},[_c('span',[_vm._v(_vm._s(_vm.properties.saveButton || _vm.$t('text.save-changes')))])]):_vm._e(),(_vm.properties.cancelVisible)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.clicked('cancel')}}},on),[_c('v-icon',[_vm._v("mdi-close-thick")])],1)]}}],null,false,481604617)},[_c('span',[_vm._v(_vm._s(_vm.properties.cancelButton || _vm.$t('text.discard-changes')))])]):_vm._e(),(_vm.properties.deleteVisible)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mx-1",attrs:{"icon":"","large":""},on:{"click":function($event){return _vm.clicked('delete')}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,false,2859704201)},[_c('span',[_vm._v(_vm._s(_vm.properties.deleteButton || _vm.$t('text.delete-record')))])]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }