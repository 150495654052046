export default class AddressUtil {
    public static build(address: any) {
        var tmp = address.line1 || "";
        if (address.line1) tmp += ",\n";
        tmp += address.line2 || "";
        if (address.line2) tmp += ",\n";
        tmp += address.line3 || "";
        if (address.line3) tmp += ",\n";
        tmp += address.line4 || "";
        if (address.line4) tmp += ",\n";
        tmp += address.postcode || "";
        if (address.postcode) tmp += " ";
		
		const city = address.city;
		if (city && city.name) {
			tmp += city.name + " ";
			if (city.name || address.postcode) { 
				tmp = tmp.trim() + ",\n";
			}
			
			const state = city.state;
			if (state && state.name) {
				tmp += state.name + ",\n";
				
				const country = state.country;
				if (country && country.name) {
					tmp += country.name;
				}
			}
		}
        tmp = tmp.trim();
        if (tmp.endsWith(",")) tmp = tmp.substr(0, tmp.length - 1);
        return tmp;
    }
}