

































































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { CustomerInfoProperties } from "./CustomerInfoView.vue";
import DatePickerInputView from "@/components/shared/DatePickerInputView.vue";
import CustomerService from "@/services/CustomerService";
import ExceptionUtil from "@/utilities/ExceptionUtil";

class CustomerExpirationDialogProperties {
    dateFormat: string = null;
    timeFormat: string = null;
    expirationDate: any = null;
    visible: boolean = false;
}

export { CustomerExpirationDialogProperties };

@Component({
    components: { DatePickerInputView },
    data: () => ({
        saving: false,
        date: null,
        hour: null,
        min: null,
        sec: null,
        period: null
    })
})
export default class CustomerExpirationDialogView extends Vue {
    @Prop({ default: new CustomerExpirationDialogProperties() })
    private properties: CustomerExpirationDialogProperties;
    @Prop() private customerInfo: CustomerInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerService = new CustomerService();

    public get hours() {
        if (this.is24Hour) {
            return Array.from(Array(24).keys());
        } else {
            return Array.from(Array(12).keys()).map(x => x + 1);
        }
    }

    public get mins() {
        return Array.from(Array(60).keys());
    }

    public get secs() {
        return Array.from(Array(60).keys());
    }

    public get periods() {
        return ['AM', 'PM'];
    }

    public get is24Hour() {
        const v = this.properties.timeFormat ?? "";
        const s = v.split(":")[0];
        return s === 'H' || s === 'HH';
    }

    public dateChanged(v: Date) {
        const year = v.getFullYear();
        const month = v.getMonth();
        const day = v.getDate();
        var hour = this.$data.hour ?? 0;
        const min = this.$data.min ?? 0;
        const sec = this.$data.sec ?? 0;

        if (!this.is24Hour) {
            if (hour === 12) {
                if (this.$data.period === "AM") {
                    hour = 0;
                } else {
                    hour = 12;
                }
            } else {
                hour += this.$data.period === "PM" ? 12 : 0;
            }
        }

        this.$data.date = new Date(year, month, day, hour, min, sec);
        this.properties.expirationDate = this.$data.date;
    }

    public hourChanged(v: any) {
        const t: Date = this.$data.date;
        const year = t.getFullYear();
        const month = t.getMonth();
        const day = t.getDate();
        var hour = this.$data.hour ?? 0;
        const min = this.$data.min ?? 0;
        const sec = this.$data.sec ?? 0;

        if (!this.is24Hour) {
            if (hour === 12) {
                if (this.$data.period === "AM") {
                    hour = 0;
                } else {
                    hour = 12;
                }
            } else {
                hour += this.$data.period === "PM" ? 12 : 0;
            }
        }

        this.$data.date = new Date(year, month, day, hour, min, sec);
        this.properties.expirationDate = this.$data.date;
    }

    public minChanged(v: any) {
        const t: Date = this.$data.date;
        const year = t.getFullYear();
        const month = t.getMonth();
        const day = t.getDate();
        var hour = this.$data.hour ?? 0;
        const min = this.$data.min ?? 0;
        const sec = this.$data.sec ?? 0;

        if (!this.is24Hour) {
            if (hour === 12) {
                if (this.$data.period === "AM") {
                    hour = 0;
                } else {
                    hour = 12;
                }
            } else {
                hour += this.$data.period === "PM" ? 12 : 0;
            }
        }

        this.$data.date = new Date(year, month, day, hour, min, sec);
        this.properties.expirationDate = this.$data.date;
    }

    public secChanged(v: any) {
        const t: Date = this.$data.date;
        const year = t.getFullYear();
        const month = t.getMonth();
        const day = t.getDate();
        var hour = this.$data.hour ?? 0;
        const min = this.$data.min ?? 0;
        const sec = this.$data.sec ?? 0;

        if (!this.is24Hour) {
            if (hour === 12) {
                if (this.$data.period === "AM") {
                    hour = 0;
                } else {
                    hour = 12;
                }
            } else {
                hour += this.$data.period === "PM" ? 12 : 0;
            }
        }

        this.$data.date = new Date(year, month, day, hour, min, sec);
        this.properties.expirationDate = this.$data.date;
    }

    public periodChanged(v: any) {
        const t: Date = this.$data.date;
        const year = t.getFullYear();
        const month = t.getMonth();
        const day = t.getDate();
        var hour = this.$data.hour ?? 0;
        const min = this.$data.min ?? 0;
        const sec = this.$data.sec ?? 0;

        if (!this.is24Hour) {
            if (hour === 12) {
                if (this.$data.period === "AM") {
                    hour = 0;
                } else {
                    hour = 12;
                }
            } else {
                hour += this.$data.period === "PM" ? 12 : 0;
            }
        }

        this.$data.date = new Date(year, month, day, hour, min, sec);
        this.properties.expirationDate = this.$data.date;
    }

    public mounted() {
        var dialog = <Vue>this.$refs.content;
        if (dialog) {
            dialog.$el.querySelectorAll(".expiration input").forEach((e: HTMLInputElement) => {
                e.style.minWidth = '0';
            });
        }

        const t = this.properties.expirationDate;
        if (t) {
            const v = new Date(t);
            this.$data.date = v;
            
            if (this.is24Hour) {
                this.$data.hour = v.getHours();
            } else {
                const h = v.getHours();
                this.$data.hour = h > 12 ? (h - 12) : h;
                this.$data.period = h < 12 ? 'AM' : 'PM';
            }
            this.$data.min = v.getMinutes();
            this.$data.sec = v.getSeconds();
        }
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const customer = this.customerInfo.customer;

            if (customer && customer.id) {
                const v = this.properties.expirationDate;
                //const r = await this.customerService.updateExpiration(customer, v);
                this.$emit("adjusted", v);
                this.properties.visible = false;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                const m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }
}
