

































import { Vue, Component, Prop } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogProperties";
import FacebookAccountHistoryService from "@/services/FacebookAccountHistoryService";
import DateUtil from "@/utilities/DateUtil";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import ResponseUtil from "@/utilities/ResponseUtil";

class CustomerNameHistoryDialogProperties {
	facebookAccountId: number = 0;
	visible: boolean = false;
}

export { CustomerNameHistoryDialogProperties };

@Component({
    data: () => ({
        table: {
            pageNo: 1,
            pageCount: 1,
            recordCount: 10,
            recordCounts: [10, 20, 30, 40, 50],
            loading: false,
        },
        histories: []
    })
})
export default class CustomerNameHistoryDialogView extends Vue {
	@Prop({ default: new CustomerNameHistoryDialogProperties() }) 
	private properties: CustomerNameHistoryDialogProperties;
	@Prop() private alertDialog: AlertDialogProperties;
	private facebookAccountHistoryService = new FacebookAccountHistoryService();
    private dateTimeFormat: string = null;

    public get headers() {
        return [
            {
                text: this.$t("text.name"),
                value: "value"
            }
        ];
    }

    public formatDate(v: any) {
        return v ? DateUtil.format(new Date(v), this.dateTimeFormat) : "";
    }

    public created() {
        this.load();
    }

    public async load() {
		const facebookAccountId = this.properties.facebookAccountId ?? 0;
        if (facebookAccountId === 0) {
            return;
        }

        const pageNo = this.$data.table.pageNo;
        const rowCount = this.$data.table.recordCount;
        const rowStart = (pageNo - 1) * rowCount;

        this.$data.table.loading = true;
        try {
            const r = await this.facebookAccountHistoryService.get({
                rowStart: rowStart,
                rowCount: rowCount,
                countRecord: true,
                facebookAccountId,
				property: 'full-name'
            });

            this.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);

            const histories = r.data.facebookAccountHistories;
            var count = Math.ceil(r.data.count / rowCount);
            if (count < 1) count = 1;

            this.$data.histories = histories.sort((lhs, rhs) => {
				return DateUtil.compare(rhs.version, lhs.version);
            });
            await Vue.nextTick();
            this.$data.table.pageCount = count;
            if (pageNo > count) {
                this.$data.table.pageNo = count;
            }
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
                this.properties.visible = false;
            }
        } finally {
            this.$data.table.loading = false;
        }
    }

    public close() {
        this.properties.visible = false;
    }
}
