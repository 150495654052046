import { AxiosResponse } from 'axios';

export default class ResponseUtil {
    public static getDateTimeFormat(r: AxiosResponse): string {
        const dateFormat = r.headers["date-format"];
        const timeFormat = r.headers["time-format"];
        if (dateFormat && timeFormat) {
            return dateFormat + ", " + timeFormat;
        } else if (dateFormat) {
            return dateFormat;
        } else if (timeFormat) {
            return timeFormat;
        } else {
            return null;
        }
    }

    public static getDateFormat(r: AxiosResponse): string {
        return r.headers["date-format"];
    }

    public static getTimeFormat(r: AxiosResponse): string {
        return r.headers["time-format"];
    }
}