import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerWalletService extends AbstractService {
    private controller = "customer-wallets";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public current(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}/current`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public adjust(d: any) {
        const url = this.makeUrl(`${this.controller}/adjust`);
        return Axios.put<any>(url, d, this.makeConfig());
    }
}