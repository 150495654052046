







































































import { Vue, Component, Mixins } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { SnackbarProperties } from "@/components/shared/SnackbarView.vue";
import { EditorControlProperties } from "@/components/shared/EditorControlView.vue";
import { CustomerWalletDialogProperties } from "./CustomerWalletDialogView.vue";
import { CustomerExpirationDialogProperties } from "./CustomerExpirationDialogView.vue";
import InfoViewMixin from "@/components/shared/InfoViewMixin";
import ExceptionUtil from "@/utilities/ExceptionUtil";
import AddressUtil from "@/utilities/AddressUtil";
import CustomerService from "@/services/CustomerService";
import ResponseUtil from "@/utilities/ResponseUtil";
import EventManager from "@/utilities/EventManager";
import CustomerWalletService from "@/services/CustomerWalletService";
import { CustomerNameHistoryDialogProperties } from "./CustomerNameHistoryDialogView.vue";

const EditorControlView = () => import("@/components/shared/EditorControlView.vue");
const AlertDialogView = () => import("@/components/shared/AlertDialogView.vue");
const SnackbarView = () => import("@/components/shared/SnackbarView.vue");
const CustomerMainView = () => import("./CustomerMainView.vue");
const CustomerAddressView = () => import("./CustomerAddressView.vue");
const CustomerWalletDialogView = () => import("./CustomerWalletDialogView.vue");
const CustomerExpirationDialogView = () => import("./CustomerExpirationDialogView.vue");
const CustomerNameHistoryDialogView = () => import("./CustomerNameHistoryDialogView.vue");

class CustomerInfoProperties {
    customer: any = { customerGroup: {}, formatted: {} };
    customerWallet: any = {};
	dateTimeFormat: string = null;
 	dateFormat: string = null;
    timeFormat: string = null;
    events = new EventManager();    
}

export { CustomerInfoProperties };

@Component({
    components: {
        EditorControlView,
        AlertDialogView,
        SnackbarView,
		CustomerMainView,
        CustomerAddressView,
        CustomerWalletDialogView,
        CustomerExpirationDialogView,
		CustomerNameHistoryDialogView
    },
    data: () => ({
		tab: null,
        saving: false,
    }),
})
export default class CustomerInfoView extends Mixins(InfoViewMixin) {
    private properties = new CustomerInfoProperties();
    private alertDialog = new AlertDialogProperties();
    private snackbar = new SnackbarProperties();
    private editorControl = new EditorControlProperties();
    private customerWalletDialog = new CustomerWalletDialogProperties();
    private customerExpirationDialog = new CustomerExpirationDialogProperties();
	private customerNameHistoryDialog = new CustomerNameHistoryDialogProperties();
    private customerService = new CustomerService();
    private customerWalletService = new CustomerWalletService();

	public get title() {
		const t1 = this.$t("title.customer");
		const t2 = this.$t("title.create");
		const t3 = this.$t("title.modify");

		return `${t1} - ${this.isCreate() ? t1 : t2}`;
	}

	public get customer() {
		return this.properties.customer;
	}

    public created() {
        this.editorControl.createVisible = false;
        this.editorControl.deleteVisible = false;
        this.mode = this.$route.params.mode;

		this.properties.events.subscribe('load', this.load);

        if (!this.checkMode() || this.isCreate()) {
            AlertDialogProperties.error(this.alertDialog, null, this.$t("message.invalid-mode"));
            this.alertDialog.tag = { command: "invalid-mode" };
            this.alertDialog.visible = true;
        } else {
            this.load();
        }
    }

	public destroyed() {
		this.properties.events.remove('load', this.load);
	}

    public async load() {
        try {
            await this.loadCustomer();
            await this.loadCustomerWallet();
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.tag = { command: "load-error" };
                this.alertDialog.visible = true;
            }
        }
    }

    public async loadCustomer() {
        var id = this.$route.query.id;
        const r = await this.customerService.get({
            id,
            hidden: false,
			loadFacebookAccount: true,
            loadCustomerGroup: true,
            loadAddresses: true,
            loadAddressCity: true,
            loadAddressState: true,
            loadAddressCountry: true
        });
        if (r.data.customers.length === 0) {
            throw new Error(this.$t("message.record-not-found").toString());
        }
        this.properties.dateTimeFormat = ResponseUtil.getDateTimeFormat(r);
        this.properties.dateFormat = ResponseUtil.getDateFormat(r);
        this.properties.timeFormat = ResponseUtil.getTimeFormat(r);

        const customer = r.data.customers[0];
        if (!customer.customerGroup) customer.customerGroup = {};
        if (!customer.addresses) customer.addresses = [];

		{
			const n = customer.name;
			const f = customer.facebookAccount;
			const u = f.uniqueNo ?? null;

			customer.formatted = {
				name: u !== null ? `${n} [${u}]` : n
			};
		}

        var addresses = customer.addresses;
        console.log(addresses);
        addresses = addresses.sort((lhs, rhs) => rhs.id - lhs.id);
        for (var i = 0; i < addresses.length; i++) {
            var tempId = Math.random();
            if (!addresses.find((x) => x.tempId === tempId)) {
                addresses[i].tempId = tempId;
                addresses[i].full = AddressUtil.build(addresses[i]);
            } else {
                i--;
            }
        }

        this.properties.customer = customer;
    }

    public async loadCustomerWallet() {
        const r = await this.customerWalletService.get({
            customerId: this.customer.id,
        });
        this.properties.customerWallet = r.data.customerWallets[0] ?? {};
    }

    public async save() {
        if (this.$data.saving) {
            return;
        }

        this.$data.saving = true;
        try {
            const customer = this.properties.customer;

            if (this.isCreate()) {
                const r = await this.customerService.post(customer);
                if (!r.data.customerGroup) {
                    r.data.customerGroup = {};
                }
                if (!r.data.addresses) {
                    r.data.addresses = [];
                }

                var addresses = r.data.addresses;
                addresses = addresses.sort((lhs, rhs) => rhs.id - lhs.id);
                for (var i = 0; i < addresses.length; i++) {
                    var tempId = Math.random();
                    if (!addresses.find((x) => x.tempId === tempId)) {
                        addresses[i].tempId = tempId;
                        addresses[i].full = AddressUtil.build(addresses[i]);
                    } else {
                        i--;
                    }
                }

                this.properties.customer = r.data;
                await this.$router.push(
                    "/management/customers/modify?id=" + this.properties.customer.id
                );
                this.mode = "modify";
                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            } else if (this.isModify()) {
                const r = await this.customerService.put(customer, true);
                if (!r.data.customerGroup) {
                    r.data.customerGroup = {};
                }
                if (!r.data.addresses) {
                    r.data.addresses = [];
                }

                var addresses = r.data.addresses;
                addresses = addresses.sort((lhs, rhs) => rhs.id - lhs.id);
                for (var i = 0; i < addresses.length; i++) {
                    var tempId = Math.random();
                    if (!addresses.find((x) => x.tempId === tempId)) {
                        addresses[i].tempId = tempId;
                        addresses[i].full = AddressUtil.build(addresses[i]);
                    } else {
                        i--;
                    }
                }

                this.properties.customer = r.data;
                AlertDialogProperties.saved(this.alertDialog, null, null);
                this.alertDialog.visible = true;
            }

            await this.loadCustomerWallet();
        } catch (e) {
            if (!(await ExceptionUtil.isAuthError(this.$router, e))) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }

        AlertDialogProperties.discard(this.alertDialog, null, null);
        this.alertDialog.tag = { command: "discard-changes" };
        this.alertDialog.visible = true;
    }

    public async control(button: string) {
        if (button === "save") {
            await this.save();
        } else if (button === "cancel") {
            this.cancel();
        }
    }


    public walletAdjusted(wallet: any) {
        this.properties.customerWallet = wallet;
    }

    public expirationAdjusted(v: any) {
        this.customer.expirationDate = v;
    }

	public async alertDialogClicked(button: string) {
        const tag = this.alertDialog.tag;
        this.alertDialog.visible = false;
        if (tag && tag.command === "discard-changes") {
            if (button === this.$t("text.yes") && !this.$data.saving) {
                await this.load();
            }
        }
        await this.alertDialogClosed();
    }

    public async alertDialogClosed() {
        if (this.alertDialog.visible) {
            return;
        }

        const tag = this.alertDialog.tag;
        if (tag) {
            if (
                tag.command === "invalid-mode" ||
                tag.command === "load-error" ||
                tag.command === "record-deleted"
            ) {
                await this.$router.push("/management/customers");
            }
        }
    }
}
