





































import { Vue, Component, Prop } from "vue-property-decorator";

class EditorControlProperties {
    createVisible: boolean = true;
    cancelVisible: boolean = true;
    saveVisible: boolean = true;
    deleteVisible: boolean = true;

    createButton: string = null;
    cancelButton: string = null;
    saveButton: string = null;
    deleteButton: string = null;
}

export { EditorControlProperties };

@Component({})
export default class EditorControlView extends Vue {
    @Prop({ default: new EditorControlProperties() }) 
    public properties: EditorControlProperties;

    private clicked(button: string) {
        this.$emit('clicked', button);
    }
}
