import AbstractService from "./AbstractService";
import Axios from "axios";

export default class CustomerService extends AbstractService {
    private controller = "customers";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }

    public post(d: any) {
        const url = this.makeUrl(`${this.controller}`);
        return Axios.post<any>(url, d, this.makeConfig());
    }

    public put(customer: any, updateAddresses: boolean) {
        const d = { customer, updateAddresses };
        const url = this.makeUrl(`${this.controller}`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public updateExpiration(customer: any, expirationDate: Date) {
        const d = { customer, expirationDate };
        const url = this.makeUrl(`${this.controller}/update-expiration`);
        return Axios.put<any>(url, d, this.makeConfig());
    }

    public user(id: number) {
        const d = { id };
        const url = this.makeUrl(`${this.controller}/user`);
        return Axios.post<any>(url, d, this.makeConfig());
    }
}