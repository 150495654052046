import { Vue, Component } from "vue-property-decorator";

@Component({})
export default class InfoViewMixin extends Vue {
    protected mode: any;

    public isCreate() {
        return this.mode === 'create';
    }

    public isModify() {
        return this.mode === 'modify';
    }

    public isView() {
        return this.mode === 'view';
    }

    public checkMode() {
        if (this.isCreate()) return true;
        if (this.isModify()) return true;
        if (this.isView()) return true;
        return false;
    }   
}