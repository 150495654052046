


























import DateUtil from '@/utilities/DateUtil';
import { Vue, Component, Prop } from "vue-property-decorator";

@Component({
    data: () => ({
        date: null,
        menu: false,
        modal: false,
    })
})
export default class DatePickerInputView extends Vue {
    @Prop({ default: null })
    private label: string;
    @Prop({ default: null })
    private value: any;
    @Prop({ default: false })
    private disabled: boolean;
    @Prop({ default: null })
    private format: string;
    @Prop({ default: false })
    private hideDetails: boolean;
    @Prop({ default: false })
    private hideIcon: boolean;
    @Prop({ default: true })
    private keepOpen: boolean;

    public get text() {
        if (!this.value) {
            return null;
        }

        if (this.format) {
            return DateUtil.format(new Date(this.value), this.format);
        } else {
            return new Date(this.value).toLocaleDateString();
        }
    }

    public set text(v: any) { } //ignored

    public get dateValue() {
        if (this.value) {
            const d = new Date(this.value ?? NaN);
            if (isNaN(d.getTime())) {
                return null;
            } else {
                return `${d.getFullYear()}-${d.getMonth() + 1}-${d.getDate()}`;
            }
        } else {
            return null;
        }
    }

    public set dateValue(v: any) { 
        if (v) {
            this.$emit("input", new Date(v + " 00:00:00"));
        } else {
            this.$emit("input", null);
        }

        if (!this.keepOpen) {
            this.$data.menu = false;
        }
    } 
}
