import AbstractService from "./AbstractService";
import Axios from "axios";

export default class FacebookAccountHistoryService extends AbstractService {
    private controller = "facebook-account-histories";

    public get(q: any = null) {
        const url = this.makeUrlWithQueries(`${this.controller}`, q);
        return Axios.get<any>(url, this.makeConfig(false));
    }
}