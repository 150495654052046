















import { Vue, Component, Prop } from "vue-property-decorator";
import { TranslateResult } from 'vue-i18n';

class SnackbarProperties {
    visible: boolean = false;
    timeout: number = 0;
    alignment: string = "bottom-right";
    message: string | TranslateResult = "";
    tag: any = null;
    closable: boolean = true;

    public static reset(properties: SnackbarProperties) {
        properties.visible = false;
        properties.timeout = 0;
        properties.alignment = "bottom-right";
        properties.message = "";
        properties.tag = null;
        properties.closable = true;
    }

}

export { SnackbarProperties };

@Component({})
export default class SnackbarView extends Vue {
    @Prop({ default: new SnackbarProperties() })
    private properties: SnackbarProperties;

    public close() {
        this.properties.visible = false;
    }

    public get isBottom() {
        return this.properties.alignment.split('-')[0] === 'bottom';
    }
    public get isTop() {
        return this.properties.alignment.split('-')[0] === 'top';
    }
    public get isLeft() {
        return this.properties.alignment.split('-')[1] === 'left';
    }
    public get isRight() {
        return this.properties.alignment.split('-')[1] === 'right';
    }
}
