















































import { AlertDialogProperties } from '@/components/shared/AlertDialogView.vue';
import CustomerWalletService from '@/services/CustomerWalletService';
import AutoNumericUtil from '@/utilities/AutoNumericUtil';
import ExceptionUtil from '@/utilities/ExceptionUtil';
import AutoNumeric, { Options } from 'autonumeric';
import { Vue, Component, Prop } from "vue-property-decorator";
import { CustomerInfoProperties } from './CustomerInfoView.vue';

class CustomerWalletDialogProperties {
    visible: boolean = false;
    currentBalance: any = "0.00";
    adjustmentAmount: any = "0.00";
    adjustedBalance: any = "0.00";
    reason: string = "";
}

export { CustomerWalletDialogProperties };

@Component({
    data: () => ({
        saving: false
    })
})
export default class CustomerWalletDialogView extends Vue {
    @Prop({ default: new CustomerWalletDialogProperties() })
    private properties: CustomerWalletDialogProperties;
    @Prop() private customerInfo: CustomerInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private customerWalletService = new CustomerWalletService();
    private inputCurrentBalance: HTMLInputElement;
    private inputAdjustmentAmount: HTMLInputElement;
    private inputAdjustedBalance: HTMLInputElement;

    public adjustmentAmountChanged() {
        const currentBalance = Number(this.properties.currentBalance) ?? 0;
        const adjustmentAmount = Number(this.properties.adjustmentAmount) ?? 0;
        const adjustedBalance = currentBalance + adjustmentAmount;

        const n1 = AutoNumericUtil.find(this.inputAdjustedBalance);
        if (n1) n1.set(adjustedBalance);
    }

    public mounted() {
        const dialog = <Vue>this.$refs.content;
        if (dialog) {
            this.inputCurrentBalance = dialog.$el.querySelector("#current-balance");
            this.inputAdjustmentAmount = dialog.$el.querySelector("#adjustment-amount");
            this.inputAdjustedBalance = dialog.$el.querySelector("#adjusted-balance");

            this.updateFormatter();
        }
    }

    public updateFormatter() {
        setTimeout(() => {
            const options: Options = {
                historySize: 0,
                outputFormat: "number",
                digitGroupSeparator: "",
                unformatOnHover: false,
                unformatOnSubmit: false,
            };

            AutoNumericUtil.findOrNew(this.inputCurrentBalance, null, options)
                .set(this.properties.currentBalance ?? 0);
            AutoNumericUtil.findOrNew(this.inputAdjustmentAmount, null, options)
                .set(this.properties.adjustmentAmount ?? 0);
            AutoNumericUtil.findOrNew(this.inputAdjustedBalance, null, options)
                .set(this.properties.adjustedBalance ?? 0)
        }, 150);
    }

    public async submit() {
        if (this.$data.saving) {
            return;
        }
        this.$data.saving = true;

        try {
            const customer = this.customerInfo.customer;

            if (customer && customer.id) {
                const r = await this.customerWalletService.adjust({
                    customerId: customer.id,
                    adjustmentAmount: this.properties.adjustmentAmount,
                    reason: this.properties.reason
                });
                this.$emit("adjusted", r.data);
                this.properties.visible = false;
            }
        } catch (e) {
            if (!await ExceptionUtil.isAuthError(this.$router, e)) {
                var m = ExceptionUtil.getMessage(e);
                AlertDialogProperties.error(this.alertDialog, null, m);
                this.alertDialog.visible = true;
            }
        } finally {
            this.$data.saving = false;
        }
    }

    public cancel() {
        if (this.$data.saving) {
            return;
        }
        this.properties.visible = false;
    }
}
